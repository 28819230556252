import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dispatch } from 'store';
import { getProjectList } from 'store/slices/project';
import { getMembers } from 'store/slices/user';
import MembersTable from 'ui-component/membersTable';
import ProjectTable from 'ui-component/projectTable';

const Members = () => {
  const { memberList, totalPage } = useSelector((state) => state.project);
  const navigate = useNavigate();
  const fetchData = (page, rowsPerPage, orderBy, order, search) => {
    dispatch(
      getMembers({
        page: page,
        limit: rowsPerPage,
        sortBy: orderBy,
        sortDirection: order,
        search: search,
      })
    );
  };
  const handleRedirect = (row) => {
    navigate(`${row.slugId}`);
  };
  return (
    <>
      <MembersTable
        title="Members List"
        rows={memberList || []}
        fetchData={fetchData}
        totalPage={totalPage}
        handleRedirect={handleRedirect}
      />
    </>
  );
};

export default Members;
