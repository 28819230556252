// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import authReducer from './slices/Auth';
import menuReducer from './slices/menu';
import commonReducer from './slices/common';
import snackbarReducer from './slices/snackbar';
import projectReducer from './slices/project';
import useReducer from './slices/user';
import calculatorReducer from './slices/calculator';
import uploadReducer from './slices/upload';
import resourcesReducer from './slices/resources';
import paymentReducer from './slices/payment';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  snackbar: snackbarReducer,
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      keyPrefix: 'user-info',
    },
    authReducer
  ),
  upload: uploadReducer,
  menu: persistReducer(
    {
      key: 'menu',
      storage,
      keyPrefix: 'menu-info',
    },
    menuReducer
  ),
  common: commonReducer,
  project: projectReducer,
  calculator: calculatorReducer,
  user: useReducer,
  resources: resourcesReducer,
  payment: paymentReducer,
});

export default reducer;
