import { styled } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Footer from './Footer/Index';
import Header from './Header/Index';

const LayoutWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  maxWidth: '100%',
  overflowX: 'hidden',
});

const ContentWrapper = styled('div')({
  position: 'relative',
  flex: 1,
});

const LandingLayout = () => {
  return (
    <LayoutWrapper>
      <Header />
      <ContentWrapper>
        <Outlet />
      </ContentWrapper>
      <Footer />
    </LayoutWrapper>
  );
};

export default LandingLayout;
