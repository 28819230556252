import {
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import { shouldForwardProp } from "@mui/system";
import { IconSearch } from "@tabler/icons-react";
import LightButton from "ui-component/button/LightButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import TuneIcon from "@mui/icons-material/Tune";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: 250,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 0,
    "& input": {
      background: "transparent !important",
      paddingLeft: "4px !important",
      padding: 12,
    },
    [theme.breakpoints.down("lg")]: {
      width: 250,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 4,
      background:
        theme.palette.mode === "dark" ? theme.palette.dark[800] : "#fff",
    },
  })
);

function EnhancedTableToolbar(props) {
  const { selectedItem } = useSelector((state) => state.menu);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSetPreferences = () => {
    navigate("/f/preference-project/add");
  };

  const title = (
    <Typography sx={{ flex: "1 1 100%" }} variant="h3" id="tableTitle">
      {props.title}
    </Typography>
  );

  const searchBox = (
    <OutlineInputStyle
      id="input-search-header"
      value={props?.search}
      onChange={(e) => props?.setSearch(e.target.value)}
      placeholder="Search for projects"
      startAdornment={
        <InputAdornment position="start">
          <IconSearch
            stroke={1.5}
            size="16px"
            color={theme.palette.grey[500]}
          />
        </InputAdornment>
      }
      aria-describedby="search-helper-text"
      inputProps={{ "aria-label": "weight" }}
    />
  );

  const preferencesBox = (
    <LightButton
      title="Preferences"
      startIcon={<TuneIcon />}
      onClick={handleSetPreferences}
      size="large"
    />
  );

  const filterBox = (
    <>
      <LightButton
        title="Filters"
        startIcon={<FilterListIcon />}
        onClick={handleClick}
        size="large"
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>Filter 1</MenuItem>
        <MenuItem onClick={handleClose}>Filter 2</MenuItem>
        <MenuItem onClick={handleClose}>Filter 3</MenuItem>
      </Menu>
    </>
  );

  return (
    <Toolbar
      sx={{
        p: 0,
        display: "block",
        // pl: { xs: 1, sm: 1 },
        // pr: { xs: 1, sm: 1 },
        // pl: { sm: 2 },
        // pr: { xs: 1, sm: 1 },
      }}
    >
      {!isSmallDevice ? (
        <>
          <Stack direction={"row"} alignItems={"center"} gap={2} mb={2}>
            {title}
            {searchBox}
            {selectedItem[0] === "projectMatchingPreference" && preferencesBox}
            {/* {filterBox} */}
          </Stack>
        </>
      ) : (
        <Stack direction={"column"} gap={2} mb={2}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {title}
            {searchBox}
          </Stack>
          {/* <Stack
            direction={"row"}
            gap={2}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            {selectedItem[0] === "projectMatchingPreference" && preferencesBox}
            {filterBox}
          </Stack> */}
        </Stack>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const TableHeader = (props) => {
  return <EnhancedTableToolbar {...props} />;
};

export default TableHeader;
