import { Box, Stack, styled, Typography, Divider } from "@mui/material";
import PartnerLog from "assets/images/partner/p1.png";
import { useNavigate } from 'react-router-dom';

const SectionWrapper = styled(Box)(({ theme }) => ({
  padding: '50px 0',
  background: theme.palette.secondary.main,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: '1000px',
  width: '100%',
  padding: '0 20px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const PartnerTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '30px',
  fontWeight: 400,
  lineHeight: '20.05px',
  letterSpacing: '-0.04em',
}));

const ContactTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '30px',
  fontWeight: 400,
  lineHeight: '40.05px',
  letterSpacing: '-0.04em',
}));

const OurPartners = () => {
  return (
    <SectionWrapper>
      <ContentWrapper>
        <Stack
          alignItems={{ xs: 'center', md: 'flex-start' }}
          justifyContent={'center'}
        >
          <PartnerTitle>Our partners</PartnerTitle>
          <a
            target="_blank"
            href="https://www.grantify.io/uk-grant-funding-quiz?utm_source=referral&utm_medium=partner&utm_campaign=prodculator"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <Box
              component={'img'}
              src={PartnerLog}
              alt="Partner Logo"
              height={120}
            />
          </a>
        </Stack>

        <Divider
          orientation={'vertical'}
          flexItem
          sx={{ mx: 4, background: 'inherit', borderColor: 'inherit' }}
        />

        <Stack
          alignItems={{ xs: 'center', md: 'flex-start' }}
          justifyContent={'center'}
        >
          <ContactTypography>To partner with us contact</ContactTypography>
          <ContactTypography>
            <a
              href="mailto:partners@prodculator.com"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              partners@prodculator.com
            </a>
          </ContactTypography>
        </Stack>
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default OurPartners;
