import { lazy } from "react";

// project imports
import GuestGuard from "utils/route-guard/GuestGuard";
import MinimalLayout from "layout/MinimalLayout";
import NavMotion from "layout/NavMotion";
import Loadable from "ui-component/Loadable";

// login routing
const AuthLogin = Loadable(
  lazy(() => import("views/pages/authentication/authentication/Login"))
);
const AuthRegister = Loadable(
  lazy(() => import("views/pages/authentication/authentication/Register"))
);
const AuthForgotPassword = Loadable(
  lazy(() => import("views/pages/authentication/authentication/ForgotPassword"))
);
const AuthResetPassword = Loadable(
  lazy(() => import("views/pages/authentication/authentication/ResetPassword"))
);
const AuthCheckMail = Loadable(
  lazy(() => import("views/pages/authentication/authentication/CheckMail"))
);
const AuthCodeVerification = Loadable(
  lazy(() =>
    import("views/pages/authentication/authentication/CodeVerification")
  )
);
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: (
    <NavMotion>
      <GuestGuard>
        <MinimalLayout />
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    {
      path: "/login",
      element: <AuthLogin />,
    },
    {
      path: "/register",
      element: <AuthRegister />,
    },
    {
      path: "/verify-otp",
      element: <AuthCodeVerification />,
    },
    {
      path: "/forgot-password",
      element: <AuthForgotPassword />,
    },
    {
      path: "/reset-password",
      element: <AuthResetPassword />,
    },
    {
      path: "/check-mail",
      element: <AuthCheckMail />,
    },
  ],
};

export default LoginRoutes;
