import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from '@mui/material';
import DarkButton from 'ui-component/button/DarkButton';
import LightButton from 'ui-component/button/LightButton';

export const Confirmation = ({
  openDialog,
  message,
  handleConfirm,
  setOpenDialog,
}) => {
  return (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton gap={2} onClick={() => setOpenDialog(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle>{message}</DialogTitle>
      <DialogActions>
        <LightButton
          title="Cancel"
          fullWidth
          onClick={() => setOpenDialog(false)}
        />
        <DarkButton title="Delete" fullWidth onClick={handleConfirm} />
      </DialogActions>
    </Dialog>
  );
};
