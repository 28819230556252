import { Navigate, createBrowserRouter } from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

// project import
import LandingLayout from 'layout/LandingLayout';
import Landing from 'views/pages/landing';
import LandingRoutes from './LandingRoutes';
import ProfileRoutes from './ProfileRoutes';
import StepFormRoutes from './StepFormRoutes';

// ==============================|| ROUTING RENDER ||============================== //

const router = createBrowserRouter(
  [
    { path: '*', element: <Navigate to="/" /> },
    {
      path: '/',
      element: <LandingLayout />,
      children: [
        {
          path: '/',
          element: <Landing />,
        },
      ],
    },
    LoginRoutes,
    MainRoutes,
    StepFormRoutes,
    LandingRoutes,
    ProfileRoutes,
  ],
  {
    basename: process.env.REACT_APP_BASE_NAME,
  }
);

export default router;
