import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import SecondaryButton from "ui-component/button/SecondaryButton";
import { ReactComponent as ContactSvg } from "../../../assets/images/svg/c-1.svg";
import { ReactComponent as MailSvg } from "../../../assets/images/svg/c-mail.svg";
import { ReactComponent as PhoneSvg } from "../../../assets/images/svg/c-mobile.svg";
import axios from "axios";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";

import BlueBg from "assets/images/svg/blue-round.svg";
import YellowBg from "assets/images/svg/yellow-round.svg";

const SectionWrapper = styled(Box)(({ theme }) => ({
  padding: "150px 60px",
  background: theme.palette.background.black,
  position: "relative",
  zIndex: 1,
  minHeight: "80vh",
  width: "100%",
  overflow: "hidden",

  ":before": {
    content: '""',
    position: "absolute",
    zIndex: -1,
    top: "-200px",
    left: "-200px",
    width: "600px",
    height: "600px",
    background: `url(${BlueBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top left",
  },

  ":after": {
    content: '""',
    position: "absolute",
    zIndex: -1,
    bottom: "-200px",
    right: "-200px",
    width: "600px",
    height: "600px",
    background: `url(${YellowBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom right",
  },

  [theme.breakpoints.down("md")]: {
    padding: "50px 30px",
  },
}));

const SectionSubTitle = styled(Typography)(({ theme }) => ({
  color: "#FFFFFFB2",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
  maxWidth: "470px",
}));

const SectionTitleMain = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: "36px",
  // fontFamily: 'Baskervville',
  fontWeight: 400,
  lineHeight: "44px",
  letterSpacing: "-0.02em",
  maxWidth: "350px",
}));

const SectionTitleSubTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: "36px",
  fontWeight: 700,
  lineHeight: "44px",
  letterSpacing: "-0.02em",
}));

const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  background: "#FFCF421A",
  display: "flex",
  justifyContent: "center",

  ":hover": {
    background: "#FFCF421A",
  },
}));

const ContactSvgStyled = styled(ContactSvg)(() => ({
  width: "100%",
}));

const ContactInputLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: "400",
  color: "#ffffff",
}));

const ContactFormWrapper = styled(Box)(({ theme }) => ({
  background: "#FFFFFF0D",
  border: "1px solid #FFFFFF1A",
  padding: theme.spacing(3.5),
  borderRadius: theme.spacing(2.5),
}));

const ContactTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    ...theme.typography.h4,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #FFFFFF1A",
    },
  },
  "& .MuiOutlinedInput-input": {
    background: "#FFFFFF0D",
    border: "1px solid #FFFFFF1A",
    color: theme.palette.background.paper,

    "&::placeholder": {
      color: "#FFFFFF99",
    },

    ":-webkit-autofill": {
      "-webkit-text-fill-color": "white",
      transition: "background-color 5000s ease-in-out 0s",
    },
  },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  marginTop: theme.spacing(3),
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: "14px",
  marginTop: theme.spacing(0.5),
}));

const ContactUs = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    reason: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "", // Clear the error when the user starts typing
    });
  };

  const validateFields = () => {
    let tempErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for email validation
    if (!formData.firstName) tempErrors.firstName = "First Name is required";
    if (!formData.lastName) tempErrors.lastName = "Last Name is required";
    if (!formData.email) {
      tempErrors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      tempErrors.email = "Invalid email format";
    }
    if (!formData.reason) tempErrors.reason = "Reason is required";
    return tempErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const tempErrors = validateFields();

    if (Object.keys(tempErrors).length > 0) {
      setErrors(tempErrors);
      return;
    }

    try {
      // Call the sendEmail API
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/app/v1/auth/send-email`,
        formData
      );

      if (response.status === 200) {
        // Reset form after successful submission
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          reason: "",
        });
        setErrors({});
        // Optionally, show a success message
        dispatch(
          openSnackbar({
            open: true,
            message: "Your email has been successfully delivered.!",
            variant: "alert",
            alert: {
              color: "success",
            },
            close: false,
          })
        );
      }
    } catch (error) {
      // Handle error
      dispatch(
        openSnackbar({
          open: true,
          message:
            error.response?.data?.message ||
            "Something went wrong. Please try again later.",
          variant: "alert",
          alert: {
            color: "error",
          },
          close: false,
        })
      );
    }
  };

  return (
    <SectionWrapper>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Stack direction={'column'} gap={1.5}>
            <SectionTitleMain>
              Join our mailing list or send us a message.
            </SectionTitleMain>
            {/* <SectionSubTitle>
              Not sure what you need? The team at square events will be happy to
              listen to you and suggest events ideas you hadn’t considered.{' '}
            </SectionSubTitle> */}
          </Stack>

          <Stack direction={'column'} gap={2} mt={4}>
            <Stack direction={'row'} alignItems={'center'} gap={1.5}>
              <IconButtonWrapper>
                <MailSvg />
              </IconButtonWrapper>
              <Typography
                variant="h4"
                fontWeight={500}
                component={Link}
                href="mailto:info@prodculator.com"
                sx={{
                  textDecoration: 'none',
                  color: 'white',
                  wordBreak: 'break-all',
                  overflowWrap: 'break-word',
                  ':hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                info@prodculator.com
              </Typography>
            </Stack>
          </Stack>

          <Box sx={{ width: 'fit-content' }} mt={4}>
            <ContactSvgStyled />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <ContactFormWrapper>
            <form onSubmit={handleSubmit}>
              <Stack mb={3.5}>
                {/* <Typography variant="h2" color="white" fontWeight={600}>
                  We'd love to hear from you!
                </Typography> */}
                <Typography
                  variant="h2"
                  color="white"
                  fontWeight={600}
                  sx={{
                    // fontFamily: 'Baskervville',
                    fontWeight: 'bold',
                  }}
                >
                  Contact Us
                  <Typography
                    sx={{
                      // fontFamily: 'Baskervville',
                      fontWeight: 'bold',
                    }}
                    component={'span'}
                    variant="h2"
                    color="secondary.main"
                    fontWeight={600}
                  ></Typography>{' '}
                </Typography>
              </Stack>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Stack direction={'column'} gap={0.5}>
                    <ContactInputLabel>First name</ContactInputLabel>
                    <ContactTextField
                      fullWidth
                      placeholder="Enter first name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                    />
                    {errors.firstName && (
                      <ErrorMessage>{errors.firstName}</ErrorMessage>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack direction={'column'} gap={0.5}>
                    <ContactInputLabel>Last name</ContactInputLabel>
                    <ContactTextField
                      fullWidth
                      placeholder="Enter last name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                    />
                    {errors.lastName && (
                      <ErrorMessage>{errors.lastName}</ErrorMessage>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction={'column'} gap={0.5}>
                    <ContactInputLabel>Company Name</ContactInputLabel>
                    <ContactTextField
                      fullWidth
                      placeholder="Enter company name"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleInputChange}
                    />
                    {errors.companyName && (
                      <ErrorMessage>{errors.companyName}</ErrorMessage>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction={'column'} gap={0.5}>
                    <ContactInputLabel>Email</ContactInputLabel>
                    <ContactTextField
                      fullWidth
                      placeholder="example123@gmail.com"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {errors.email && (
                      <ErrorMessage>{errors.email}</ErrorMessage>
                    )}
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction={'column'} gap={0.5}>
                    <ContactInputLabel>How can we help?</ContactInputLabel>
                    <ContactTextField
                      fullWidth
                      placeholder="Write a summary..."
                      name="reason"
                      value={formData.reason}
                      onChange={handleInputChange}
                      multiline
                      rows={10}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderRadius: '16px',
                          },
                        },

                        '& .MuiInputBase-input.MuiOutlinedInput-input': {
                          borderRadius: '16px',
                        },
                      }}
                    />
                    {errors.reason && (
                      <ErrorMessage>{errors.reason}</ErrorMessage>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <ButtonWrapper>
                    <SecondaryButton title={'Submit'} type="submit" />
                  </ButtonWrapper>
                </Grid>
              </Grid>
            </form>
          </ContactFormWrapper>
        </Grid>
      </Grid>
    </SectionWrapper>
  );
};

export default ContactUs;
