import LAYOUT_CONST from "constant";

export const DASHBOARD_PATH = "/c/project";
export const HORIZONTAL_MAX_ITEM = 7;

const config = {
  layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
  drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default,
  fontFamily: `'SfProDisplay', sans-serif`,
  borderRadius: 8,
  outlinedFilled: true,
  navType: "light", // light, dark
  locale: "en", // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  container: false,
};

export default config;
