import {
  OutlinedInput,
  Stack,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { shouldForwardProp } from '@mui/system';
import PropTypes from 'prop-types';
import React from 'react';

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: 250,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 0,
    '& input': {
      background: 'transparent !important',
      paddingLeft: '4px !important',
      padding: 15,
    },
    [theme.breakpoints.down('lg')]: {
      width: 250,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 4,
      background:
        theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff',
    },
  })
);

function EnhancedTableToolbar(props) {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const [anchorEl, setAnchorEl] = React.useState(null);

  const title = (
    <Typography sx={{ flex: '1 1 100%' }} variant="h3" id="tableTitle">
      {props.title}
    </Typography>
  );

  return (
    <Toolbar
      sx={{
        p: 0,
        display: 'block',
      }}
    >
      {!isSmallDevice ? (
        <>
          <Stack direction={'row'} alignItems={'center'} gap={2} mb={2}>
            {title}
          </Stack>
        </>
      ) : (
        <Stack direction={'column'} gap={2} mb={2}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            {title}
          </Stack>
        </Stack>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const TableHeader = (props) => {
  return <EnhancedTableToolbar {...props} />;
};

export default TableHeader;
