import { Box, Grid, Stack, Typography, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BlueBg from 'assets/images/svg/blue-round-big.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/svg/ch-right.svg';
import YellowBg from 'assets/images/svg/yellow-round.svg';
import { useNavigate } from 'react-router-dom';
import SecondaryButton from 'ui-component/button/SecondaryButton';

// ==============================|| LANDING - HeroSection PAGE ||============================== //

const HeroSectionWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  background: theme.palette.background.black,
  position: 'relative',
  zIndex: 1,
  minHeight: '90vh',
  width: '100%',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  ':before': {
    content: '""',
    position: 'absolute',
    zIndex: -1,
    top: '-200px',
    left: '-200px',
    width: '400px', // Adjusted for smaller screens
    height: '400px',
    background: `url(${YellowBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top left',
    backgroundSize: 'contain',
  },

  ':after': {
    content: '""',
    position: 'absolute',
    zIndex: -1,
    top: '50%',
    right: '0',
    transform: 'translate(50%, -50%)',
    width: '1200px', // Adjusted for responsiveness
    height: '1200px',
    background: `url(${BlueBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
    minHeight: '80vh',
  },
}));

const SectionTagline = styled(Typography)(({ theme }) => ({
  color: theme.palette.background.paper,
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '24px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));

const SectionTitleMain = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: '800',
  fontSize: '72px', // Reduced for responsiveness
  lineHeight: '80px',
  textAlign: 'center',
  maxWidth: 1000,

  [theme.breakpoints.down('md')]: {
    fontSize: '48px',
    lineHeight: '52px',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '36px',
    lineHeight: '42px',
  },
}));

const SectionTitleHeighLightedTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: '800',
  fontSize: '72px', // Reduced to match SectionTitleMain
  lineHeight: '80px',

  [theme.breakpoints.down('md')]: {
    fontSize: '48px',
    lineHeight: '52px',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '36px',
    lineHeight: '42px',
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '28px', // Adjusted for smaller screens
  textAlign: 'center',
  fontWeight: 400,
  letterSpacing: '-0.04em',

  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
    lineHeight: '26px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '600',
  },
}));

const SubTitleTwo = styled(Typography)(({ theme }) => ({
  color: theme.palette.background.paper,
  fontSize: '22px', // Adjusted for mobile
  textAlign: 'center',
  fontWeight: 500,
  letterSpacing: '-0.04em',
  maxWidth: 600,

  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
    lineHeight: '22px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '600',
  },
}));

const SubTitleTwoLighted = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '22px', // Match with SubTitleTwo
  textAlign: 'center',
  fontWeight: 800,
  letterSpacing: '-0.04em',
  cursor: 'pointer',
  transition: 'color 0.3s ease, text-decoration 0.3s ease',

  '&:hover': {
    color: theme.palette.secondary.dark,
    textDecoration: 'none',
  },

  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
    lineHeight: '22px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '600',
  },
}));

const HeroSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleCalculatorClick = () => {
    navigate('/tax-calculator');
  };

  return (
    <HeroSectionWrapper>
      <Grid
        container
        sx={{
          flexGrow: 1,
          justifyContent: 'center',
          padding: theme.spacing(2),
        }}
      >
        <Grid item xs={12} md={10}>
          <Stack gap={4} alignItems="center" textAlign="center">
            <SectionTitleMain>
              <SectionTitleHeighLightedTxt component="span">
                Empowering
              </SectionTitleHeighLightedTxt>{' '}
              the <br /> creative industry
            </SectionTitleMain>

            <SubTitle>
              With financial tools, global funding, and grant opportunities
            </SubTitle>

            <SubTitleTwo>
              Find out if you qualify for United Kingdom or South Africa Film
              and TV tax incentives with our{' '}
              <SubTitleTwoLighted
                component="span"
                onClick={handleCalculatorClick}
              >
                FREE CALCULATOR
              </SubTitleTwoLighted>
            </SubTitleTwo>

            <SectionTagline>
              More countries and funding opportunities coming soon.
            </SectionTagline>

            <SecondaryButton
              onClick={handleCalculatorClick}
              title="Calculate Now"
              endIcon={<ArrowIcon />}
            />
          </Stack>
        </Grid>
      </Grid>
    </HeroSectionWrapper>
  );
};

export default HeroSection;
