import React from 'react';
import BgImage from 'assets/images/landing/tc.png';
import styled from '@emotion/styled';
import { Box, Grid, Stack, Typography } from '@mui/material';
import SecondaryButton from 'ui-component/button/SecondaryButton';
import { useNavigate } from 'react-router-dom';

const SectionWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  background: theme.palette.common.black,
  backgroundImage: `url(${BgImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  height: '96vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 24px',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    padding: '100px 24px',
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  background: '#252317',
  border: '1px solid #FFFFFF1A',
  color: theme.palette.secondary.main,
  fontSize: '8px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '-0.02em',
  textAlign: 'center',
  width: 'fit-content',
  padding: '4px 12px',
  borderRadius: '24px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '6px',
    padding: '2px 8px',
  },
}));

const SectionTitleMain = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px',
  letterSpacing: '-0.02em',
  textAlign: 'center',
  maxWidth: '90%',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    lineHeight: '28px',
  },
}));

const SectionTitleFreeTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px',
  letterSpacing: '-0.02em',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    lineHeight: '28px',
  },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2),
  },
}));

const MainTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h1,
  fontWeight: 600,
  fontSize: '32px',
  color: theme.palette.secondary.main,
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  fontWeight: 500,
  color: theme.palette.background.paper,
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
  },
}));

const TermsAndConditions = () => {
  const navigate = useNavigate();
  return (
    <SectionWrapper>
      <Grid container justifyContent={'center'}>
        <Grid item xs={12} md={10}>
          <Stack direction={'column'} alignItems={'center'} gap={3}>
            <MainTitle>We are a dynamic platform designed to empower</MainTitle>

            <SubTitle>
              the creative community by connecting creative professionals with
              global funders and international Grants.
            </SubTitle>

            <br />
            <SectionTitleMain>
              Use our{' '}
              <SectionTitleFreeTxt component="span"> free </SectionTitleFreeTxt>{' '}
              UK/South Africa Film and TV tax incentive calculator{' '}
            </SectionTitleMain>
            <SectionTitle>
              More countries plus funding opportunities coming soon.
            </SectionTitle>
          </Stack>

          <ButtonWrapper>
            <SecondaryButton
              onClick={() => navigate('/tax-calculator')}
              title={'Film Tax Rebate & Incentive Calculator'}
            />
          </ButtonWrapper>
        </Grid>
      </Grid>
    </SectionWrapper>
  );
};

export default TermsAndConditions;
