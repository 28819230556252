import { memo, useMemo, useState } from "react";

// material-ui
import { Box, Drawer, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

import useConfig from "hooks/useConfig";
import { drawerWidth } from "store/constant";

import { useDispatch, useSelector } from "store";
import { openDrawer } from "store/slices/menu";
import LogoSection from "ui-component/logoSection";
import StepForm from "views/creative/project/add/StepForm";
import PreferenceProjectStepForm from "views/funder/preferenceProject/add/StepForm";
import { useLocation } from "react-router-dom";

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(1);
  const { selectedItem } = useSelector((state) => state.menu);

  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);

  const { layout, drawerType } = useConfig();

  const logo = useMemo(
    () => (
      <Box sx={{ display: "flex", p: 2, mx: "auto" }}>
        <LogoSection />
      </Box>
    ),
    []
  );

  const drawerContent = (
    <>
      {selectedItem[0] === "project" && <StepForm />}{" "}
      {selectedItem[0] === "projectMatchingPreference" && (
        <PreferenceProjectStepForm />
      )}{" "}
    </>
  );

  const drawerSX = {
    paddingLeft: drawerOpen ? "16px" : 0,
    paddingRight: drawerOpen ? "30px" : 0,
    marginTop: drawerOpen ? "32px" : "32px",
  };

  const drawer = useMemo(
    () => (
      <>
        {matchDownMd ? (
          <Box sx={drawerSX}>{drawerContent}</Box>
        ) : (
          <PerfectScrollbar
            component="div"
            style={{
              height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
              ...drawerSX,
            }}
          >
            {drawerContent}
          </PerfectScrollbar>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [matchUpMd, drawerOpen, drawerType]
  );

  return (
    <Box
      sx={{
        flexShrink: { md: 0 },
        width: { xs: 0, md: drawerWidth },
      }}
    >
      <Drawer
        variant={matchUpMd ? "persistent" : "temporary"}
        open={drawerOpen}
        onClose={() => dispatch(openDrawer(!drawerOpen))}
        sx={{
          "& .MuiDrawer-paper": {
            mt: matchDownMd ? 0 : 11, //88
            zIndex: 1099,
            width: drawerWidth,
            background: theme.palette.background.bgMain,
            color: theme.palette.text.primary,
            borderRight: "1px solid transparent",
            backgroundColor: theme.palette.background.bgMain,
            boxShadow: "5px 5px 10px 0px #00000040",
            height: "calc(95vh - 88px)",
            overflow: "auto",
            padding: theme.spacing(3, 2),
            position: "relative",
            borderRadius: theme.spacing(0, 2, 2, 0),

            [theme.breakpoints.down("md")]: {
              height: "100vh",
            },

            [theme.breakpoints.up("md")]: {
              transform: "translateX(0px) !important",
              visibility: "visible !important",
              borderRadius: theme.spacing(2),
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {matchDownMd && logo}
        {drawer}
      </Drawer>
    </Box>
  );
};

export default memo(Sidebar);
