// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  userType: [],
  userSubType: [],
  members: [],
  totalMembers: 0,
  userDetails: {},
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    getUserTypeSuccess(state, action) {
      state.userType = action.payload;
    },
    getUserSubTypeSuccess(state, action) {
      state.userSubType = action.payload;
    },
    getMembersSuccess(state, action) {
      state.members = action.payload.items;
      state.totalMembers = action.payload.total;
    },
    getUserDetailsSuccess(state, action) {
      state.userDetails = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { logoutSuccess } = slice.actions;

export function getUserType(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.get('/app/v1/auth/userType', payload);
      dispatch(slice.actions.getUserTypeSuccess(response.data.data));
      return response.data;
    } catch (error) {
      // ...
      throw error;
    }
  };
}
export function getUserSubType(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.get('/app/v1/auth/subUserType', payload);
      dispatch(slice.actions.getUserSubTypeSuccess(response.data.data));
      return response.data;
    } catch (error) {
      // ...
      throw error;
    }
  };
}
export function getMembers(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/app/v1/user/public', payload);
      dispatch(slice.actions.getMembersSuccess(response.data.data));
      return response.data;
    } catch (error) {
      // ...
      throw error;
    }
  };
}
export function getUserDetails({ slugId }) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/app/v1/user/${slugId}`);
      dispatch(slice.actions.getUserDetailsSuccess(response.data.data));
      return response.data;
    } catch (error) {
      // ...
      throw error;
    }
  };
}
