import { memo, useEffect, useMemo, useState } from "react";

// material-ui
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ProfilePlaceHolder from "assets/images/other/profile-placeholder.jpg";

// project imports
import MenuList from "../MenuList";

import { drawerWidth } from "store/constant";

import { useDispatch, useSelector } from "store";
import { openDrawer } from "store/slices/menu";
import LogoSection from "ui-component/logoSection";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation, useNavigate } from "react-router-dom";
import { getProfile } from "store/slices/Auth";

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector((state) => state.auth.authData.data);
  const { profile } = useSelector((state) => state.auth);
  const { fileResponse } = useSelector((state) => state.upload);
  const [profilePic, setProfilePic] = useState(ProfilePlaceHolder);

  const { pathname } = useLocation();
  const prefix = pathname.split("/")[1];
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);

  const logo = useMemo(
    () => (
      <Box sx={{ display: "flex", p: 2, mx: "auto" }}>
        <LogoSection />
      </Box>
    ),
    []
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    navigate("/login");
  };
  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    if (fileResponse) {
      setProfilePic(fileResponse);
    } else if (profile.profileImage) {
      setProfilePic(profile.profileImage);
    } else {
      setProfilePic(ProfilePlaceHolder);
    }
  }, [fileResponse, profile.profileImage]);

  const drawerContent = (
    <>
      <MenuList />
    </>
  );

  const handleEdit = () => {
    setAnchorEl(null);
    navigate("/p/personal-information");
  };
  const goProjectList = () => {
    setAnchorEl(null);
    if (user.userType.userTypeId === 1) {
      navigate("/c/project");
    } else {
      navigate("/f/preference-project");
    }
  };
  const handleRedirect = () => {
    setAnchorEl(null);
    navigate(`/c/member/${profile?.slugId}`);
  };

  const userContent = (
    <>
      <Box sx={{ flexGrow: 1 }} />

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack direction={"row"} gap={1.5} alignItems={"center"}>
          <Box
            component={"img"}
            src={profilePic}
            sx={{ height: "46px", width: "46px", borderRadius: "50%" }}
          />
          <Stack gap={0.5}>
            <Typography variant="h4">{user?.fullName}</Typography>
            <Typography variant="h6" sx={{ color: "#23232299" }}>
              {user?.userType?.name}
            </Typography>
          </Stack>
        </Stack>

        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              borderRadius: 8,
              marginTop: "-70px",
              marginLeft: "30px",
              minWidth: "200px",
            },
          }}
        >
          <MenuItem onClick={handleRedirect}>
            <ListItemIcon>
              <AccountCircleIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">View Profile</Typography>
          </MenuItem>
          {prefix === "p" ? (
            <MenuItem onClick={goProjectList}>
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Project list</Typography>
            </MenuItem>
          ) : (
            <>
              <>
                <MenuItem onClick={handleEdit}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Edit Profile</Typography>
                </MenuItem>
              </>
            </>
          )}
          <Divider />

          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon
                fontSize="small"
                sx={{ color: theme.palette.error.main }}
              />
            </ListItemIcon>
            <Typography
              variant="inherit"
              sx={{ color: theme.palette.error.main }}
            >
              Logout
            </Typography>
          </MenuItem>
        </Menu>
      </Stack>
    </>
  );

  return (
    <Box
      sx={{
        flexShrink: { md: 0 },
        width: { xs: 0, md: drawerWidth },
      }}
    >
      <Drawer
        variant={matchUpMd ? "persistent" : "temporary"}
        open={drawerOpen}
        onClose={() => dispatch(openDrawer(!drawerOpen))}
        sx={{
          "& .MuiDrawer-paper": {
            mt: matchDownMd ? 0 : 11, //88
            zIndex: 1099,
            width: drawerWidth,
            background: theme.palette.background.bgMain,
            color: theme.palette.text.primary,
            borderRight: "1px solid transparent",
            backgroundColor: theme.palette.background.bgMain,
            boxShadow: "5px 5px 10px 0px #00000040",
            height: "calc(95vh - 88px)",
            overflow: "auto",
            padding: theme.spacing(3, 2),
            position: "relative",
            borderRadius: theme.spacing(0, 2, 2, 0),

            [theme.breakpoints.down("md")]: {
              height: "100vh",
            },

            [theme.breakpoints.up("md")]: {
              transform: "translateX(0px) !important",
              visibility: "visible !important",
              borderRadius: theme.spacing(2),
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {matchDownMd && logo}
        {drawerContent}
        {userContent}
      </Drawer>
    </Box>
  );
};

export default memo(Sidebar);
