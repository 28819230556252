import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import StepFormLayout from "../layout/StepFormLayout";

const AddProject = Loadable(lazy(() => import("views/creative/project/add")));
const AddPreferenceProject = Loadable(
  lazy(() => import("views/funder/preferenceProject/add"))
);

const StepFormRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <StepFormLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/project/add',
      element: <AddProject />,
    },
    {
      path: '/f/preference-project/add',
      element: <AddPreferenceProject />,
    },
  ],
};

export default StepFormRoutes;
