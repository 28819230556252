// material-ui
import { Avatar, Box, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// project imports
import HeaderLinkSection from "./HeaderLinkSection";

import { useDispatch, useSelector } from "store";
import { openDrawer } from "store/slices/menu";
import LogoSection from "ui-component/logoSection";
// assets
import { IconMenu2 } from "@tabler/icons-react";
import NotificationSection from "./NotificationSection";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      p={2}
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>

        {matchDownMd && (
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              display: { xs: "flex", md: "none" },
              overflow: "hidden",
              transition: "all .2s ease-in-out",
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              "&:hover": {
                background: theme.palette.secondary.dark,
                color: theme.palette.background.paper,
              },
            }}
            onClick={() => dispatch(openDrawer(!drawerOpen))}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="20px" />
          </Avatar>
        )}
      </Box>

      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems={"center"}
        sx={{ flexGrow: 1 }}
      >
        <Stack direction={"row"} alignItems={"center"} gap={1.3}>
          <HeaderLinkSection />
          <NotificationSection />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Header;
